<template>
  <div class="arret" :class="pageClass">
    <Banner>
      <div class="banner-content">
        <MeepIconSocial class="banner-content__icon" />
        <span class="banner-content__text">{{ $t("menu.socials") }}</span>
      </div>
    </Banner>

    <div
      class="arret-content page-layout"
      :class="{ 'arret-content--jep': isJEP, 'arret-content--lcm': isLCM }"
    >
      <LoadingCard v-if="isLoading" />

      <md-card v-else class="meep-form">
        <PageHeader
          :title="$t('socials.arret.title')"
          :has-back="true"
          :has-search="false"
          :has-actions="false"
          @back="goBack"
        />
        <!-- le formulaire de création -->
        <md-card-content>
          <!-- Numéro de SIREN -->
          <div class="meep-form-column socials-form">
            <div class="meep-form-column-title">
              <p class="meep-form-column-title-number">1</p>
              <p class="meep-form-column-title-text">
                {{ $t("socials.arret.section-1-title") }}
              </p>
            </div>

            <!-- Motif Select -->
            <div class="meep-input">
              <md-field>
                <label>
                  {{ $t("socials.arret.motif") }}
                </label>

                <md-select v-model="$v.form.type.$model">
                  <md-option
                    v-for="(type, index) in types"
                    :key="index"
                    :value="type.value"
                  >
                    {{ type.text }}
                  </md-option>
                </md-select>
              </md-field>

              <template v-if="$v.form.type.$dirty">
                <span v-show="!$v.form.type.required" class="meep-error">
                  {{ $t("meep-errors.required") }}
                </span>
              </template>
            </div>

            <div class="meep-form-column-title">
              <p class="meep-form-column-title-number">2</p>
              <p class="meep-form-column-title-text">
                {{ $t("socials.arret.section-2-title") }}
              </p>
            </div>

            <!-- Company Select -->
            <div class="meep-input">
              <md-field>
                <label>
                  {{ $t("socials.company") }}
                </label>

                <md-select v-model="form.companyId">
                  <md-option
                    v-for="(company, index) in companies"
                    :key="index"
                    :value="company.id"
                  >
                    {{ company.name }}
                  </md-option>
                </md-select>
              </md-field>
            </div>
            <!-- Employee Select -->
            <div v-if="this.form.companyId" class="meep-input">
              <md-field>
                <label>
                  {{ $t("socials.employee") }}
                </label>

                <md-select
                  v-model="form.employee"
                  @md-selected="changeEmployee"
                >
                  <md-option
                    v-for="(employee, index) in companyEmployees"
                    :key="index"
                    :value="index"
                  >
                    {{ employee.text }}
                  </md-option>
                </md-select>
              </md-field>
            </div>
            <!-- Last Name -->
            <div class="meep-input">
              <md-field>
                <label>{{ $t("socials.lastName") }}</label>
                <md-input v-model="$v.form.lastName.$model"> </md-input>
              </md-field>
              <template v-if="$v.form.lastName.$dirty">
                <span v-show="!$v.form.lastName.alphaSpace" class="meep-error">
                  {{ $t("meep-errors.alphaSpace") }}
                </span>
                <span v-show="!$v.form.lastName.required" class="meep-error">
                  {{ $t("meep-errors.required") }}
                </span>
              </template>
            </div>

            <!-- First Name -->
            <div class="meep-input">
              <md-field>
                <label>{{ $t("socials.firstName") }}</label>
                <md-input v-model="$v.form.firstName.$model"> </md-input>
              </md-field>
              <template v-if="$v.form.firstName.$dirty">
                <span v-show="!$v.form.firstName.alphaSpace" class="meep-error">
                  {{ $t("meep-errors.alphaSpace") }}
                </span>
                <span v-show="!$v.form.firstName.required" class="meep-error">
                  {{ $t("meep-errors.required") }}
                </span>
              </template>
            </div>

            <!-- Last Day -->
            <template v-if="form.type === 'arret'">
              <div class="meep-input">
                <md-datepicker v-model="$v.form.lastDay.$model">
                  <label>
                    {{ $t("socials.arret.lastDay") }}
                  </label>
                </md-datepicker>
                <template v-if="$v.form.lastDay.$dirty">
                  <span v-show="!$v.form.lastDay.required" class="meep-error">
                    {{ $t("meep-errors.required") }}
                  </span>
                </template>
              </div>

              <!-- End Date -->
              <div class="meep-input">
                <md-datepicker v-model="$v.form.endDate.$model">
                  <label>
                    {{ $t("socials.arret.endDate") }}
                  </label>
                </md-datepicker>
                <template v-if="$v.form.endDate.$dirty">
                  <span v-show="!$v.form.endDate.required" class="meep-error">
                    {{ $t("meep-errors.required") }}
                  </span>
                </template>
              </div>
            </template>

            <template v-else>
              <div class="meep-input">
                <md-field>
                  <label>{{ $t("socials.arret.caracteristique") }}</label>
                  <md-input v-model="$v.form.caracteristique.$model">
                  </md-input>
                </md-field>
                <template v-if="$v.form.caracteristique.$dirty">
                  <span
                    v-show="!$v.form.caracteristique.required"
                    class="meep-error"
                  >
                    {{ $t("meep-errors.required") }}
                  </span>
                  <span
                    v-show="!$v.form.caracteristique.alphaSpace"
                    class="meep-error"
                  >
                    {{ $t("meep-errors.alphaSpace") }}
                  </span>
                </template>
              </div>

              <div class="meep-input">
                <md-datepicker v-model="$v.form.newDate.$model">
                  <label>
                    {{ $t("socials.arret.newDate") }}
                  </label>
                </md-datepicker>
                <template v-if="$v.form.newDate.$dirty">
                  <span v-show="!$v.form.newDate.required" class="meep-error">
                    {{ $t("meep-errors.required") }}
                  </span>
                </template>
              </div>
            </template>
          </div>

          <div class="meep-form-column socials-form">
            <div class="meep-form-column-title">
              <p class="meep-form-column-title-number">3</p>
              <p class="meep-form-column-title-text">
                {{ $t("socials.arret.section-3-title") }}
              </p>
            </div>

            <Chooser
              v-model="form.subrogation"
              :value="form.subrogation"
              :items="[
                { label: 'Oui', value: true },
                { label: 'Non', value: false },
              ]"
            />

            <div v-if="form.subrogation" class="meep-input">
              <md-datepicker v-model="form.subrogationDate">
                <label>
                  {{ $t("socials.arret.subrogationDate") }}
                </label>
              </md-datepicker>
            </div>

            <!-- Motif Select -->
            <div class="meep-input">
              <md-field>
                <label>
                  {{ $t("socials.arret.motif") }}
                </label>

                <md-select v-model="form.motif">
                  <md-option
                    v-for="(motif, index) in motifContrat"
                    :key="index"
                    :value="motif.value"
                  >
                    {{ motif.text }}
                  </md-option>
                </md-select>
              </md-field>
            </div>

            <div class="meep-form-column-title">
              <p class="meep-form-column-title-number">4</p>
              <p class="meep-form-column-title-text">
                {{ $t("socials.arret.section-4-title") }}
              </p>
            </div>

            <GetPicture
              ref="picture"
              :nameGenerator="generateImageName"
              @change="changeImage"
            />
            <div class="md-layout md-alignment-center-right create-button">
              <md-button class="md-raised md-primary" @click.native="save">
                {{ $t("socials.button-text") }}
              </md-button>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import companiesModel from "../../model/companies";
import employeeModel from "../../model/employee";
import PageHeader from "@/components/PageHeader";
import Banner from "@/components/Banner";
import MeepIconSocial from "@/components/icons/MeepIconSocial.vue";
import LoadingCard from "@/components/LoadingCard";
import GetPicture from "@/components/GetPicture.vue";
import Chooser from "@/components/Chooser.vue";
import { createFileObject, filterEmployeesCompanyId } from "@/services/util";
import { motifContrat } from "../../constants";
import { alphaSpace } from "../../services/vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import FilesModel from "@/model/files";

export default {
  name: "FinDeContrat",

  components: {
    LoadingCard,
    Banner,
    MeepIconSocial,
    PageHeader,
    GetPicture,
    Chooser,
  },
  computed: {
    ...mapGetters([
      "isAdmin",
      "isCollab",
      "isExternal",
      "isClient",
      "isSuperUser",
      "organizationTheme",
      "isCE",
      "isJEP",
      "isJEPA",
      "isLCM",
    ]),
    pageClass() {
      return {
        "arret--ce": this.isCE,
        "arret--jep": this.isJEP,
        "arret--jepa": this.isJEPA,
      };
    },
    companyEmployees() {
      return filterEmployeesCompanyId(this.employees, this.form.companyId);
    },
  },

  data() {
    return {
      groups: [],
      isLoading: false,
      form: {
        type: "arret",
        employee: null,
        lastName: "",
        firstName: "",
        lastDay: null,
        endDate: null,
        subrogation: false,
        subrogationDate: "",
        motif: "",
        companyId: null,
        caracteristique: "",
        newDate: null,
        justificatif: null,
      },
      fileName: null,
      companies: [],
      employees: [],
      types: [
        {
          text: "Arrêt de travail initial",
          value: "arret",
        },
        {
          text: "Prolongation d'un arrêt de travail",
          value: "prolongation",
        },
      ],
      motifContrat,
    };
  },

  validations: {
    form: {
      firstName: {
        alphaSpace,
        required,
      },
      lastName: {
        alphaSpace,
        required,
      },

      type: { required },

      lastDay: { required },

      endDate: { required },

      newDate: {
        required: requiredIf(function() {
          return (this.form.type = "prolongation");
        }),
      },

      caracteristique: {
        alphaSpace,
        required: requiredIf(function() {
          return (this.form.type = "prolongation");
        }),
      },

      motif: { required },
    },
  },

  mounted() {
    this.loadData();
  },

  methods: {
    generateImageName() {
      if (this.form.type === "prolongation")
        return `Prolongation--${Date.now()}.jpg`;
      return `Arret-travail--${Date.now()}.jpg`;
    },

    changeImage(imageName) {
      this.fileName = imageName;
      this.form.justif = imageName;
    },

    goBack() {
      window.history.back();
    },

    async loadData() {
      try {
        this.isLoading = true;
        this.companies = await companiesModel.getAll();
        this.employees = await employeeModel.getAllEmployee();

        this.isLoading = false;
      } catch (err) {
        this.$toasted.global.AppError({
          message: err.msg,
        });

        this.isLoading = false;
      }
    },

    async save() {
      const dataObject = { data: this.form };
      try {
        if (this.fileName) {
          const file = this.$refs.picture.$data.file;
          createFileObject(dataObject, file, this.fileName);

          await FilesModel.uploadDsn(dataObject);
        } else await FilesModel.uploadDsn(dataObject);

        this.$toasted.global.AppSucces({
          message: this.$t("socials.arret.success"),
        });
        this.$router.push("/dashboard/sociale/");
      } catch (error) {
        this.$toasted.global.AppError({
          message: error.msg,
        });
      }
    },

    changeEmployee(index) {
      this.form.lastName = this.companyEmployees[index].value.lastName;
      this.form.firstName = this.companyEmployees[index].value.firstName;
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/_variable.scss";
#app {
  .arret {
    .get-picture-container {
      margin: 0;
    }
    .arret-content .md-card.meep-form .md-card-content {
      align-items: unset;
    }
    .meep-form-column {
      display: flex;
      flex-direction: column;
    }
    .create-button {
      margin: auto 0 0 0;
    }
    .chooser {
      margin-top: 0;
      .v-radio .v-label {
        font-size: toRem(9);
        @include for-lg {
          font-size: toRem(15);
        }
      }
    }
    &-content {
      padding: toRem(30);
      .md-card.meep-form.md-theme-default {
        @include for-lg {
          padding: toRem(34) toRem(46) toRem(46) toRem(50);
        }
        .page-header {
          margin: 0;
          &__item {
            margin-bottom: 0;
          }
        }
      }
      .md-card {
        .md-card-content {
          .socials-form.meep-form-column {
            .meep-form-column-title {
              margin: toRem(23) 0 toRem(12) 0;
              gap: toRem(12);
              @include for-lg {
                margin: toRem(32) 0 toRem(16) 0;
                gap: toRem(16);
              }
              .meep-form-column-title-text {
                font-size: toRem(11);
                @include for-lg {
                  font-size: toRem(16);
                }
              }
            }
            &:first-child {
              .meep-form-column-title {
                margin-bottom: toRem(5);
                @include for-lg {
                  margin: toRem(32) 0 toRem(11) 0;
                }
              }
            }
            .md-button {
              font-size: toRem(9);
              margin-right: 0;
              @include for-lg {
                font-size: toRem(14);
              }
            }
            .meep-input {
              .md-field {
                margin: toRem(7) 0 0 0;
                @include for-lg {
                  margin: toRem(10) 0 0 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
